export const appConfigMockData = {
    appConfig: {
        local: {
            release_1_1: {
                createProject: true,
                showAssetDetails: true,
                showEditAssetNickName: true,
                showOpsPlannerChange: true
            }
        }
    }
};
