import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CatButton } from "blocks-nextjs/blocks-react/components/Button/Button";
import { CatHeading } from "blocks-nextjs/blocks-react/components/Heading/Heading";
import { CatModal } from "blocks-nextjs/blocks-react/components/Modal/Modal";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CaasRoute } from "../../routes";
import { PageEvents } from "../../shared/pageEvents";
import useGlobalStore from "../../store/useGlobalStore";
import CustomerSearch from "./CustomerSearch";
const CustomerSearchModal = () => {
    const CUSTOMER_UCID_SELECTED = "partyNumber";
    const [partyNumber, setPartyNumber] = useState("");
    const [viewProjectButtonDisabled, setViewProjectButtonDisabled] = useState(true);
    const getPageLoadingCount = () => {
        const count = window?.sessionStorage?.getItem("customerSearchModelPageCount");
        if (!count) {
            window.sessionStorage.setItem("customerSearchModelPageCount", "0");
        }
        else {
            window.sessionStorage.setItem("customerSearchModelPageCount", String(Number(count) + 1));
        }
        return window?.sessionStorage?.getItem("customerSearchModelPageCount");
    };
    useEffect(() => {
        const storedPartyNumber = localStorage.getItem(CUSTOMER_UCID_SELECTED);
        if (storedPartyNumber) {
            setUserPartyNumber(extractPartyNumberFromSelectedCustomer(storedPartyNumber));
            setIsLoggedInAsCAT(true);
            setCustomerName(storedPartyNumber);
        }
        else {
            const pageLoadingCount = getPageLoadingCount();
            if (Number(pageLoadingCount) > 0) {
                dataLayer.push({
                    event: PageEvents.PAGE_VIEW,
                    page_location: window.location.href, // setting the full url path
                    page_title: "customer search model page"
                });
            }
        }
        dataLayer.push({
            event: "formVisible",
            formName: "select projects"
        });
    }, []);
    useEffect(() => {
        partyNumber.length > 0 ? setViewProjectButtonDisabled(false) : setViewProjectButtonDisabled(true);
    }, [partyNumber]);
    const { setCustomerName, setIsLoggedInAsCAT, setUserPartyNumber } = useGlobalStore((state) => ({
        setCustomerName: state.setCustomerName,
        setIsLoggedInAsCAT: state.setIsLoggedInAsCAT,
        setUserPartyNumber: state.setUserPartyNumber
    }));
    const navigate = useNavigate();
    const dataLayer = (window["dataLayer"] = window["dataLayer"] || []);
    function extractPartyNumberFromSelectedCustomer(partyNumber) {
        const match = partyNumber.match(/\d+/);
        return match ? match[0] : partyNumber;
    }
    const handleModalCancel = () => {
        setUserPartyNumber("");
        navigate(CaasRoute.Home);
    };
    const showProjectsForCustomer = (event) => {
        event.preventDefault();
        setCustomerName(partyNumber);
        setIsLoggedInAsCAT(true);
        setUserPartyNumber(extractPartyNumberFromSelectedCustomer(partyNumber));
        dataLayer.push({
            event: PageEvents.FORM_SUBMITTED,
            formAdditionalContent: null,
            formContent: partyNumber,
            formContent2: "select projects customer form",
            formFieldCausingError: null,
            formLocation: "home page",
            formName: "select projects",
            formStatus: "success",
            pagePath: CaasRoute.Home
        });
        localStorage.setItem(CUSTOMER_UCID_SELECTED, partyNumber);
        navigate(CaasRoute.Home);
    };
    return (_jsx("div", { "data-test": "customer-selection-modal-containter", children: _jsx("form", { id: "select projects", name: "select projects form", onSubmit: (event) => {
                return showProjectsForCustomer(event);
            }, children: _jsxs(CatModal, { disableBackdropClick: true, disableEscClick: true, dismissible: false, fullWidth: false, isActive: true, onBlModalCancel: handleModalCancel, size: "lg", children: [_jsx(CatHeading, { "data-test": "customer-selection-header", level: "h4", slot: "header", children: "Select or search a customer from the list below" }), _jsx(CustomerSearch, { partyNumber: partyNumber, setPartyNumber: setPartyNumber, size: "lg" }), _jsx("div", { className: "flex flex-col items-center", "data-test": "customer-selection-footer", slot: "footer", children: _jsx(CatButton, { "data-test": "customer-selection-button", disabled: viewProjectButtonDisabled, type: "submit", variant: "primary", children: "View Projects" }) })] }) }) }));
};
export default CustomerSearchModal;
