import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CatHeader } from "blocks-nextjs/blocks-react/components/Header/Header";
import { CatIconHelp } from "blocks-nextjs/blocks-react/components/Icons/Help";
import { CatIconPerson } from "blocks-nextjs/blocks-react/components/Icons/Person";
import { CatList } from "blocks-nextjs/blocks-react/components/List/List";
import { CatListItem } from "blocks-nextjs/blocks-react/components/ListItem/ListItem";
import { CatUtilityNav } from "blocks-nextjs/blocks-react/components/UtilityNav/UtilityNav";
import { CatUtilityNavItem } from "blocks-nextjs/blocks-react/components/UtilityNavItem/UtilityNavItem";
import React, { useEffect, useState } from "react";
import useAppConfigStore from "../../store/useAppConfigStore";
import { CatTextPassage } from "blocks-nextjs/blocks-react/components/TextPassage/TextPassage";
import { CatTooltip } from "blocks-nextjs/blocks-react/components/Tooltip/Tooltip";
import { matchPath } from "react-router";
import { useLocation, useNavigate } from "react-router-dom";
import catLogo from "../../assets/CatLogo.svg";
import { CaasRoute } from "../../routes";
import useCreateNewProjectStore from "../../store/useCreateNewProjectStore";
import useGlobalStore from "../../store/useGlobalStore";
import NewProjectModal from "../CreateNewProject/NewProjectModal";
const Header = () => {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const isRestrictedPathActive = !!matchPath(pathname, "/restricted-access");
    const [isModalActive, setIsModalActive] = useState(false);
    const [isFuncCookieEnabled, setIsFuncCookieEnabled] = useState(false);
    const [showTooltip, setShowTooltip] = useState(false);
    const { isLoggedInAsCAT } = useGlobalStore((state) => ({
        isLoggedInAsCAT: state.isLoggedInAsCAT
    }));
    const { appConfig } = useAppConfigStore((state) => ({
        appConfig: state.appConfig
    }));
    const { projectAddress, projectName, projectOwner } = useCreateNewProjectStore((state) => ({
        projectAddress: state.projectAddress,
        projectName: state.projectName,
        projectOwner: state.projectOwner
    }));
    const checkNewProjectPathAndRenderModal = () => {
        const isCreateNewProjectPathActive = !!matchPath(pathname, "/new-project");
        if (isCreateNewProjectPathActive && projectName && projectOwner && projectAddress) {
            setIsModalActive(true);
            return true;
        }
        return false;
    };
    useEffect(() => {
        const checkFuncCookie = () => {
            //using for OneTrust Functional Cookie enable/disable
            if (window.OnetrustActiveGroups && window.OnetrustActiveGroups.includes("C0003")) {
                setIsFuncCookieEnabled(true);
            }
            else {
                setIsFuncCookieEnabled(false);
            }
        };
        checkFuncCookie();
        window.addEventListener("OneTrustGroupsUpdated", checkFuncCookie);
        return () => {
            window.removeEventListener("OneTrustGroupsUpdated", checkFuncCookie);
        };
    }, []);
    const handleHelpClick = () => {
        if (!isFuncCookieEnabled) {
            setShowTooltip(!showTooltip);
        }
    };
    const openCookieSettings = () => {
        setShowTooltip(false);
        if (window.OneTrust) {
            window.OneTrust.ToggleInfoDisplay();
        }
    };
    return (_jsxs(CatHeader, { "data-test": "cat-header", children: [_jsx("img", { className: "mx-4 cursor-pointer h-[32px] w-[60px]", "data-test": "cat-logo", onClick: () => {
                    if (!checkNewProjectPathAndRenderModal()) {
                        return !isRestrictedPathActive && navigate(CaasRoute.Home);
                    }
                    return null;
                }, src: catLogo }), isLoggedInAsCAT && (_jsxs("div", { className: "ml-auto font-bold text-white", children: ["Selected Customer: ", localStorage.getItem("partyNumber")] })), _jsxs(CatUtilityNav, { children: [appConfig?.release_1_1?.showWhatFix && (_jsxs("div", { className: "flex items-center text-white relative", "data-test": "guide-walkthrough", children: [_jsx(CatIconHelp, { className: "cat-icon pr-2 cursor-pointer", "data-test": "guided-tours", size: "lg" }), !isFuncCookieEnabled ? (_jsxs(CatTooltip, { isActive: showTooltip, isInteractive: true, position: "bottom", children: [_jsx("span", { className: "cursor-pointer text-sm", onClick: handleHelpClick, slot: "trigger", children: "Help center" }), _jsx(CatTextPassage, { size: "sm", children: _jsxs("span", { className: "text-gray-800 font-medium", children: ["Enable functional cookies to access Guided Tours. You can enable them in your\u00A0", _jsx("a", { className: "ot-sdk-show-settings hover:underline cursor-pointer text-blue-600", "data-test": "cookie-settings", onMouseDown: () => {
                                                        openCookieSettings();
                                                    }, children: "Cookie Settings." })] }) })] })) : (_jsx("span", { className: "cursor-pointer text-sm", children: "Help center" }))] })), _jsxs(CatUtilityNavItem, { "data-test": "cat-utility-nav-logout", dropdown: true, children: [_jsx(CatIconPerson, { className: "cat-icon", slot: "before" }), _jsx(CatList, { className: "c-top-nav__list", spacing: "padded", children: _jsx(CatListItem, { className: "c-top-nav__list-item", "data-test": "cat-utility-logout-option", onClick: () => navigate(CaasRoute.Logout), children: "Logout" }) })] })] }), _jsx(NewProjectModal, { onModalClose: (modelState) => {
                    setIsModalActive(modelState);
                }, showModal: isModalActive })] }));
};
export default Header;
