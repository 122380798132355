import { differenceInHours, format } from "date-fns";
import bigAssBattery from "../../assets/BigAssBattery.svg";
import cat950Icon from "../../assets/Cat950.png";
import pswIcon from "../../assets/PswIcon.svg";
import defaultMarkerIcon from "../../assets/defaultMarkerIcon.png";
import { NodeIconColor, NodeIconType } from "../../components/CustomNodes/CustomNodeEnums";
import { Activity } from "../../swagger/caas-v1/index";
export const getNetworkNodes = (assetData, projectName, projectId, projectDetails) => {
    const networkNodes = [];
    const ewx = 450;
    let ewy = 100;
    const etx = 660;
    let ety = 150;
    const esx = 850;
    let esy = 100;
    const msx = 1050;
    let msy = 100;
    const chargeStationPositionX = 250;
    let chargeStationPositionY = 180;
    const CHARGE_STATION_ADD_VALUE_Y = 270;
    const siteESSPos = new Map();
    const siteMachinePos = [];
    const sortedData = sortNetworkData(assetData);
    const chargeStationData = [];
    sortedData.forEach((data) => {
        let xc;
        let yc;
        let assetIcon;
        const ADD_VALUE = 120;
        if (data?.assetSourceType?.toUpperCase() === "ESS" || data?.assetSourceType?.toUpperCase() === "CHARGER") {
            if (data?.activity === "Transporting") {
                xc = etx;
                yc = ety;
                ety = ety + ADD_VALUE;
                assetIcon = NodeIconType.TRUCKWITHESS;
            }
            else if (data?.assetTransitDirection?.toUpperCase() ===
                (AssetTransitDirection.AT_SITE || AssetTransitDirection.AT_SOURCE)) {
                xc = esx;
                yc = esy;
                esy = esy + ADD_VALUE;
                siteESSPos.set(data.assetId, yc);
                assetIcon = NodeIconType.ESS;
            }
            else {
                xc = ewx;
                yc = ewy;
                ewy = ewy + ADD_VALUE;
                assetIcon = NodeIconType.ESS;
            }
        }
        if (data?.assetSourceType?.toUpperCase() === "MACHINE") {
            xc = msx;
            let essY = getChargingSourcePosition(data.chargingSource, siteESSPos);
            if (essY !== null) {
                while (siteMachinePos.includes(essY)) {
                    essY = essY + ADD_VALUE;
                }
                yc = essY;
            }
            else {
                while (siteMachinePos.includes(msy)) {
                    msy = msy + ADD_VALUE;
                }
                yc = msy;
                msy = msy + ADD_VALUE;
            }
            siteMachinePos.push(yc);
            assetIcon = NodeIconType.EXCAVATOR;
        }
        if (projectDetails?.find((item) => item?.assetId === data?.assetId && item?.assetMetadata?.productFamily?.code === "MDWL")) {
            assetIcon = NodeIconType.WHEELLOADER;
        }
        if (data?.assetSourceType?.toUpperCase() === "CHARGER") {
            if (data?.activity === "Idle") {
                assetIcon = NodeIconType.IDLEMECCHARGER;
            }
            else {
                assetIcon = NodeIconType.CHARGEMECCHARGER;
            }
        }
        if (data?.assetSourceType?.toUpperCase() === "CHARGING_STATION") {
            chargeStationData.push(data);
        }
        data?.assetSourceType?.toUpperCase() !== "CHARGING_STATION" &&
            networkNodes.push({
                data: {
                    activity: data?.activity,
                    assetTransitDirection: projectName === "Hansweert" && projectId === "308a84bb-fc3a-442e-92c9-5508086385f0"
                        ? undefined
                        : data?.assetTransitDirection,
                    hasSource: data?.assetSourceType === "ESS" || data?.assetSourceType.toUpperCase() === "CHARGER",
                    hasTarget: data?.chargingSource !== "" && data?.chargingSource !== undefined,
                    iconColor: getNodeIconColor(data?.activity),
                    iconType: assetIcon,
                    label: getAssetName(data?.assetName, data?.assetSourceType),
                    selected: false,
                    toolTip: {
                        address: data?.location?.streetAddress,
                        assetDetailsLink: "/asset-page-details/" + data?.assetId,
                        assetEnergy: data?.assetEnergy,
                        assetLatestPower: data?.assetLatestPower,
                        assetName: data?.assetName,
                        chargeTime: data?.estimatedRemainingChargeTime,
                        distance: data?.odometerReading,
                        distanceUom: "Miles",
                        hours: data?.lifetimeRunningHours,
                        isReporting: isAssetReporting(data?.location?.lastReportedTime),
                        soc: data?.soc,
                        socTrend: data?.socTrend,
                        state: data?.activity
                    }
                },
                id: data?.assetId,
                position: { x: xc, y: yc },
                type: "assetNode"
            });
    });
    if (networkNodes.length !== 0) {
        networkNodes.push({
            data: {},
            id: "Power",
            position: { x: 100, y: 288 },
            type: "utilityPowerNode"
        });
        const getChargeStationLabel = (assetName) => {
            if (projectName === "Amsterdam" && projectId === "d85bd393-6cc3-4461-bcf0-78365d5bbf12") {
                return "Grid Connection";
            }
            const words = assetName?.replace(/_/g, " ").split(" ");
            const capitalizedWords = words?.map((word) => word.charAt(0).toUpperCase() + word.slice(1));
            return capitalizedWords.join(" ");
        };
        chargeStationData.forEach((data) => {
            networkNodes.push({
                data: { label: getChargeStationLabel(data?.assetName) },
                id: data.assetId,
                position: { x: chargeStationPositionX, y: chargeStationPositionY },
                type: "chargeStationNode"
            });
            chargeStationPositionY = chargeStationPositionY + CHARGE_STATION_ADD_VALUE_Y;
        });
    }
    return networkNodes;
};
const sortNetworkData = (assetData) => {
    const essData = [];
    const chargingMachines = [];
    const otherData = [];
    assetData.forEach((data) => {
        if (data.assetSourceType === "ESS" || data?.assetSourceType?.toUpperCase() === "CHARGER") {
            essData.push(data);
        }
        else if (data.assetSourceType?.toUpperCase() === "MACHINE" &&
            data.chargingSource !== null &&
            data.chargingSource.toUpperCase() !== "OTHERS") {
            chargingMachines.push(data);
        }
        else {
            otherData.push(data);
        }
    });
    return essData.concat(chargingMachines).concat(otherData);
};
const getChargingSourcePosition = (source, essPos) => {
    let pos = null;
    if (source === null || source.toUpperCase() === "OTHERS")
        return null;
    essPos.forEach((value, key) => {
        if (source === key) {
            pos = value;
        }
    });
    return pos;
};
export const getNodeIconColor = (state) => {
    if (state === Activity.CHARGING) {
        return NodeIconColor.CHARGING;
    }
    if (state === Activity.DISCHARGING) {
        return NodeIconColor.DISCHARGING;
    }
    if (state === Activity.IDLE) {
        return NodeIconColor.IDLE;
    }
    if (state === Activity.TRANSPORTING) {
        return NodeIconColor.TRANSPORTING;
    }
    return NodeIconColor.INACTIVE;
};
const isAssetReporting = (time) => {
    const MILLI_SECONDS_IN_HOUR = 3600000;
    const HOURS = 18;
    const machineTime = new Date(time + "Z").getTime();
    const milliDiff = Date.now() - machineTime;
    const hourDiff = Math.floor(milliDiff / MILLI_SECONDS_IN_HOUR);
    return hourDiff < HOURS;
};
export const getNetworkEdges = (assetData) => {
    const networkEdges = [];
    const edgeMap = new Map();
    let n = 0;
    assetData.forEach((data) => {
        if (data?.chargingSource) {
            edgeMap.set(data.assetId, data.chargingSource);
        }
        if (data?.assetSourceType?.toUpperCase() === "CHARGING_STATION") {
            edgeMap.set(data.assetId, "Power");
        }
    });
    for (const [key, value] of edgeMap.entries()) {
        networkEdges.push({ id: "e" + n, source: value, style: { strokeWidth: 3 }, target: key });
        n++;
    }
    return networkEdges;
};
export const getAssetName = (assetName, assetSourceType) => {
    const FIRST_POSITION = 0;
    const MACHINE_LAST_POSITION = 5;
    const COMMON_LAST_POSITION = 3;
    const LAST_POSITION = assetSourceType?.toUpperCase() === "MACHINE" ? MACHINE_LAST_POSITION : COMMON_LAST_POSITION;
    if (assetName && assetSourceType === "CHARGING_STATION") {
        return assetName
            .replace(/_([a-z])/g, (_, char) => ` ${char.toUpperCase()}`)
            .replace(/^./, (char) => char.toUpperCase());
    }
    else {
        return assetName ? `${assetName.substring(FIRST_POSITION, LAST_POSITION)}` : "";
    }
};
export const trimAssetName = (assetName) => {
    const updatedAssetName = assetName.split("/");
    return updatedAssetName.length ? updatedAssetName[0] : assetName;
};
export const isLastReportedTimeStale = (lastReportedTime) => {
    const EIGHTEEN = 18;
    if (!lastReportedTime) {
        return true;
    }
    const lastReportedDate = new Date(lastReportedTime);
    if (isNaN(lastReportedDate.getTime())) {
        return true;
    }
    const currentTime = new Date();
    const hoursDifference = differenceInHours(currentTime, lastReportedDate);
    return hoursDifference > EIGHTEEN;
};
export const getAssetImageIcon = (assetDetails) => {
    if (assetDetails?.assetId === "9fd64572-d32a-4473-951a-5d16a053646e" ||
        assetDetails?.assetId === "5fe212ea-8fa8-4d71-a020-d3e4b504ee3a" ||
        assetDetails?.assetId === "0fa9aedd-8b63-4436-b17f-f88fc4994290") {
        return cat950Icon;
    }
    else if (assetDetails?.assetMetadata?.make === "PSW") {
        return pswIcon;
    }
    else if (assetDetails?.assetSourceType === "ESS" && assetDetails?.assetMetadata?.productFamily?.code === "BAB") {
        return bigAssBattery;
    }
    return defaultMarkerIcon;
};
const getLabels = (activities) => {
    const uniqueLabels = [];
    const fixedLabels = [];
    const activityLabels = [];
    if (!activities || activities.length < 1)
        return [];
    activities.forEach((activity) => {
        const chargeAsset = formatAssetLabelForSankey(activity.assetName);
        const dischargeAsset = formatAssetLabelForSankey(activity.dischargeAssetName);
        if (!uniqueLabels.includes(chargeAsset))
            uniqueLabels.push(chargeAsset);
        if (!uniqueLabels.includes(dischargeAsset))
            uniqueLabels.push(dischargeAsset);
    });
    uniqueLabels.forEach((label) => {
        if (label === "WATTHUB" || label?.includes("Residual")) {
            fixedLabels.push(label);
        }
        else {
            activityLabels.push(label);
        }
    });
    //updatedLabels
    return fixedLabels.concat(activityLabels);
};
const getSankeyChartLabels = (activities) => {
    const uniqueLabels = [];
    if (!activities || activities.length < 1)
        return [];
    activities.forEach((activity) => {
        const chargeAsset = formatAssetLabelForSankey(activity.assetName);
        const dischargeAsset = formatAssetLabelForSankey(activity.dischargeAssetName);
        if (!uniqueLabels.includes(chargeAsset))
            uniqueLabels.push(chargeAsset);
        if (!uniqueLabels.includes(dischargeAsset))
            uniqueLabels.push(dischargeAsset);
    });
    return uniqueLabels;
};
const getLinks = (activities, labels) => {
    if (!activities || activities.length < 1)
        return { source: [], target: [], value: [] };
    const source = activities.map((activity) => labels.findIndex((label) => label === formatAssetLabelForSankey(activity.assetName)));
    const target = activities.map((activity) => labels.findIndex((label) => label === formatAssetLabelForSankey(activity.dischargeAssetName)));
    const value = activities.map((activity) => activity.dischargeEnergy);
    return {
        source,
        target,
        value
    };
};
const getSankeyChartLinks = (activities) => {
    const links = [];
    activities.map((activity) => {
        links.push({
            source: formatAssetLabelForSankey(activity.assetName),
            target: formatAssetLabelForSankey(activity.dischargeAssetName),
            value: parseFloat(activity.dischargeEnergy)
        });
    });
    return links;
};
const formatAssetLabelForSankey = (label) => {
    let words;
    if (label?.includes("(")) {
        words = label.split("(");
    }
    else {
        words = label?.split("-");
    }
    return words !== undefined ? words[0] : label;
};
const getPositions = (labels) => {
    const x = [];
    const y = [];
    if (!labels || labels.length < 1)
        return { x: [], y: [] };
    let ey = 0.0;
    let my = 0.1;
    let oy = 0.0;
    labels.map((label) => {
        // To fix any deviations of data, where the node would be rendered too far
        // from its ideal position. For now this is not needed, but leaving this
        // mapping there, if needed in future
        if (label === "WATTHUB") {
            const wattHubXValue = 0.0;
            const wattHubYValue = 0.5;
            x.push(wattHubXValue);
            y.push(wattHubYValue);
        }
        else if (label.startsWith("1") ||
            label.startsWith("3") ||
            label.startsWith("4") ||
            label.includes("Other Machine")) {
            const otherMachineXValue = 0.4;
            x.push(otherMachineXValue);
            my = my + 0.1;
            y.push(my);
        }
        else if (label.startsWith("CQ") ||
            label.startsWith("BQ") ||
            label.startsWith("VO") ||
            label.startsWith("RL") ||
            label.startsWith("RO") ||
            label.includes("Other ESS")) {
            const otherESSValue = 0.2;
            x.push(otherESSValue);
            ey = ey + 0.1;
            y.push(ey);
        }
        else {
            x.push(1);
            oy = oy + 0.1;
            y.push(oy);
        }
    });
    return {
        x,
        y
    };
};
const getCustomData = (labels) => labels.map((label) => label.toString());
export const prepareSankeyData = (activities) => {
    const labels = getLabels(activities);
    const links = getLinks(activities, labels);
    const positions = getPositions(labels);
    const customData = getCustomData(labels);
    return {
        customData,
        labels,
        links,
        positions
    };
};
const prepareSankeyChartNodes = (labels) => {
    const nodes = [];
    labels.map((label) => {
        // mapping there, if needed in future
        if (label.toUpperCase() === "WATTHUB" ||
            label === "Office Grid Connection" ||
            label === "Site Grid Connection" ||
            label.toLowerCase() === "unknown") {
            nodes.push({ id: label, type: "CHARGING_STATION", xPos: 50 });
        }
        else if (label.startsWith("1") ||
            label.startsWith("3") ||
            label.startsWith("4") ||
            label.startsWith("9") ||
            label.includes("Other Machine")) {
            nodes.push({ id: label, type: "MACHINE", xPos: 500 });
        }
        else if (label.startsWith("CQ") ||
            label.startsWith("BQ") ||
            label.startsWith("VO") ||
            label.startsWith("RL") ||
            label.startsWith("RO") ||
            label.startsWith("AC") ||
            label.startsWith("DC") ||
            label.startsWith("XES") ||
            label.includes("Other ESS")) {
            nodes.push({ id: label, type: "ESS", xPos: 250 });
        }
        else if (label.includes("Residual") || label.includes("Energy Loss")) {
            nodes.push({ id: label, type: "RESIDUAL", xPos: 500 });
        }
        else {
            nodes.push({ id: label, type: "OTHER", xPos: 1000 });
        }
    });
    return nodes;
};
export const prepareSankeyChartData = (activities) => {
    const formatAssetName = (name) => {
        if (name.toLowerCase() === "office_grid_connection") {
            return "Office Grid Connection";
        }
        else if (name.toLowerCase() === "site_grid_connection") {
            return "Site Grid Connection";
        }
        else {
            return name;
        }
    };
    activities.forEach((activity) => {
        activity.assetName = formatAssetName(activity.assetName);
        activity.dischargeAssetName = formatAssetName(activity.dischargeAssetName);
    });
    const labels = getSankeyChartLabels(activities);
    const links = getSankeyChartLinks(activities);
    const nodes = prepareSankeyChartNodes(labels);
    return {
        links,
        nodes
    };
};
export const extractHourlyEnergyData = (energyUsageHistory) => {
    const hourlyUsage = [];
    if (energyUsageHistory && energyUsageHistory.energyHistogram && energyUsageHistory.energyHistogram["hourly"]) {
        energyUsageHistory.energyHistogram["hourly"].map((usage) => {
            hourlyUsage.push(Math.round(usage.value));
        });
    }
    return hourlyUsage;
};
export const getLocalTime = (utc, offset) => {
    if (isNotEmpty(utc) && isNotEmpty(offset)) {
        // Below if condition for test site where date format is not in ISO format
        const regex = /^\d{2}:\d{2}:\d{2}$/;
        if (regex.test(utc)) {
            utc = new Date().toISOString().split("T")[0] + "T" + utc;
        }
        const words = offset.split("UTC");
        const offsetTime = parseInt(words[1]);
        const utcDate = new Date(utc);
        if (isNaN(utcDate.valueOf())) {
            return null;
        }
        const MILLI_SECONDS = 3600000;
        return new Date(utcDate.getTime() + offsetTime * MILLI_SECONDS);
    }
    return null;
};
function isNotEmpty(str) {
    return typeof str === "string" && str.trim() !== "";
}
export var AssetTransitDirection;
(function (AssetTransitDirection) {
    AssetTransitDirection["AT_SITE"] = "ATSITE";
    AssetTransitDirection["AT_SOURCE"] = "ATSOURCE";
    AssetTransitDirection["TOWARDS_SITE"] = "TOWARDSSITE";
    AssetTransitDirection["TOWARDS_SOURCE"] = "TOWARDSSOURCE";
})(AssetTransitDirection || (AssetTransitDirection = {}));
export const getSankeyNodeColor = (type) => {
    switch (type) {
        case "ESS":
            return "#8A9A5B";
        case "MACHINE":
            return "#e0ac2b";
        case "CHARGING_STATION":
            return "#6689c6";
        case "RESIDUAL":
            return "#808080";
        default:
            return "#9a6fb0";
    }
};
export const extractDailyEnergyData = (energyUsageHistory) => {
    const formattedChargeEnergy = [], formattedDischargeEnergy = [], formattedDate = [];
    let totalDischarge = 0;
    if (energyUsageHistory &&
        energyUsageHistory.energyHistogram &&
        energyUsageHistory.energyHistogram["dailyWiseMonth"]) {
        energyUsageHistory.energyHistogram["dailyWiseMonth"].forEach((usage) => {
            const { chargeEnergy = 0, date = "", dischargeEnergy = 0 } = usage;
            const formattedDateStr = format(new Date(date), "dd-MMM");
            formattedChargeEnergy.push(Math.round(chargeEnergy));
            formattedDischargeEnergy.push(Math.round(dischargeEnergy));
            formattedDate.push(formattedDateStr);
            totalDischarge += dischargeEnergy;
        });
    }
    return {
        formattedChargeEnergy,
        formattedDate,
        formattedDischargeEnergy,
        totalDischarge
    };
};
