import { jsx as _jsx } from "react/jsx-runtime";
import { CatLoadingIndicator } from "blocks-nextjs/blocks-react/components/LoadingIndicator/LoadingIndicator";
import React, { useContext, useEffect } from "react";
import { AuthContext } from "react-oauth2-code-pkce";
const logoutChannel = new BroadcastChannel("logout");
const Logout = () => {
    const { logOut } = useContext(AuthContext);
    useEffect(() => {
        logoutChannel.postMessage("Logout");
        logOut();
    }, []);
    return (_jsx("div", { className: "h-screen w-screen flex items-center justify-center", children: _jsx(CatLoadingIndicator, { "data-test": "blocks-loading-icon" }) }));
};
export const logoutFromAllTabs = () => {
    logoutChannel.onmessage = () => {
        window.location.assign("/logout");
        logoutChannel.close();
    };
};
export default Logout;
