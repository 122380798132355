import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import axios from "axios";
import { CatLoadingIndicator } from "blocks-nextjs/blocks-react/components/LoadingIndicator/LoadingIndicator";
import { sha256 } from "js-sha256";
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "react-oauth2-code-pkce";
import { useLocation, useNavigate } from "react-router-dom";
import CustomerSearchModal from "./components/CustomerSearch/CustomerSearchModal";
import Footer from "./components/Footer/Footer";
import GlobalNotification from "./components/GlobalNotification/GlobalNotification";
import Header from "./components/Header/Header";
import UserAgreement from "./components/UserAgreement/UserAgreement";
import { getAppEnv } from "./lib/getEnvVariables";
import { logoutFromAllTabs } from "./pages/Logout/Logout";
import Router from "./routes";
import { useEntitlementsQuery } from "./services/query/entitlements-api.query";
import { useAppConfigQuery } from "./services/query/project-conifg.query";
import { httpStatus } from "./shared/constants";
import { PageEvents } from "./shared/pageEvents";
import { getUserPartyNumber } from "./shared/validateUserPermission";
import useAppConfigStore from "./store/useAppConfigStore";
import useGlobalStore from "./store/useGlobalStore";
const App = () => {
    const { error, logIn, token, tokenData } = useContext(AuthContext);
    const { pathname, search } = useLocation();
    const navigate = useNavigate();
    const { setAppConfig } = useAppConfigStore((state) => ({
        setAppConfig: state.setAppConfig
    }));
    const { globalNotification, isAgreementAccepted, setUserEntitlements, setUserPartyNumber, userEntitlements, userPartyNumber } = useGlobalStore((state) => ({
        globalNotification: state.globalNotification,
        isAgreementAccepted: state.isAgreementAccepted,
        setUserEntitlements: state.setUserEntitlements,
        setUserPartyNumber: state.setUserPartyNumber,
        userEntitlements: state.userEntitlements,
        userPartyNumber: state.userPartyNumber
    }));
    /**
     * These useEffects handle the dataLayer push required for Google Analytics:
     *   - PageNavigationEvent only after the intial reder
     *   - Then every route pathname/search change (paths, drawers and modals)
     *   - b2cloaded event after the token is loaded
     *
     */
    const dataLayer = (window["dataLayer"] = window["dataLayer"] || []);
    const [gtmPrepValues, setGtmPrepValues] = useState({ hasTokenData: false, needsNavigation: false });
    useEffect(() => {
        if (gtmPrepValues.needsNavigation && gtmPrepValues.hasTokenData) {
            dataLayer.push({
                event: PageEvents.PAGE_NAVIGATION_EVENT,
                pagePath: pathname ?? "",
                query: search ?? ""
            });
        }
        else {
            setGtmPrepValues({ ...gtmPrepValues, needsNavigation: true });
        }
        if (tokenData && !gtmPrepValues.hasTokenData) {
            const { aud, catafltnclass, catafltncode, catafltnorgcode, catloginid, catrecid, cattoplevelorgcode, client_id, company, email_address } = tokenData || {};
            dataLayer.push({
                B2CObjectID: aud,
                B2CUserType: catafltnclass,
                HEM: sha256(email_address),
                affClass: catafltnclass,
                affCode: catafltncode,
                affiliationOrgCode: catafltnorgcode?.toLowerCase(),
                affiliationOrgName: company?.toLowerCase(),
                catRecId: catrecid,
                companyId: company,
                event: PageEvents.B2CLOADED,
                loginId: sha256(catloginid),
                topLvlOrgCode: cattoplevelorgcode || null
                // Do not have these in the token...
                // topLvlOrgCode
                // B2CdealerCode,
                // B2CdealerName,
            });
            setGtmPrepValues({ ...gtmPrepValues, hasTokenData: true });
            // pushing page load values into data layer
            dataLayer.push({
                companyName: company,
                customerID: client_id,
                siteID: "eflow",
                templateName: "projects home page"
            });
            loadGtmjs(window, document, "script", "dataLayer", "GTM-TPHLJ8QQ");
        }
    }, [pathname, search, tokenData]);
    const loadGtmjs = (w, d, s, l, i) => {
        dataLayer.push({ event: PageEvents.GTMJS, "gtm.start": new Date().getTime() });
        const f = d.getElementsByTagName(s)[0];
        const j = d.createElement(s);
        const dl = l !== "dataLayer" ? "&l=" + l : "";
        j.async = true;
        j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
        f.parentNode.insertBefore(j, f);
    };
    // Fetch Entitlements
    const { data: permissions, error: entitlementsError, isError: isEntitlementsError, status: entitlementApiStatus } = useEntitlementsQuery();
    useEffect(() => {
        if (JSON.stringify(permissions) !== JSON.stringify(userEntitlements)) {
            setUserEntitlements(permissions);
            setUserPartyNumber(getUserPartyNumber(permissions));
            if (permissions?.catrecid) {
                window["_wfx_cat_userIdentifier"] = { catRecId: permissions?.catrecid };
            }
            if (permissions?.primaryPartyType) {
                window["_wfx_cat_partyType"] = { partyType: permissions?.primaryPartyType };
            }
            if (permissions?.userRole) {
                window["_wfx_cat_userRole"] = { userRole: permissions?.userRole };
            }
        }
        if (axios.isAxiosError(entitlementsError) && entitlementsError.response?.status === httpStatus.ERROR_403) {
            navigate("/restricted-access");
        }
    }, [permissions, isEntitlementsError]);
    // Set App Config from appConfig.json
    const { data: { appConfig = {} } = {} } = useAppConfigQuery();
    useEffect(() => {
        if (appConfig?.[getAppEnv()] !== undefined) {
            setAppConfig(appConfig?.[getAppEnv()]);
        }
    }, [appConfig]);
    /**
     * Logout broadcast accross tabs
     */
    useEffect(() => {
        logoutFromAllTabs();
    }, []);
    /**
     * If not logged in properly, redirect to login page
     */
    if (!token && error) {
        logIn();
    }
    /**
     * Still getting token information, show a loader
     */
    if (!token || entitlementApiStatus !== "success") {
        return (_jsx("div", { className: "h-screen w-screen flex items-center justify-center", children: _jsx(CatLoadingIndicator, { "data-test": "blocks-loading-icon" }) }));
    }
    const determineContentToRender = () => {
        if (isAgreementAccepted) {
            return userPartyNumber === "CAT" ? _jsx(CustomerSearchModal, {}) : _jsx(Router, {});
        }
        else {
            return _jsx(Router, {});
        }
    };
    const restrictedPage = pathname === "/restricted-access";
    return (_jsxs("div", { className: "flex flex-col min-h-screen", "data-test": "app", children: [!restrictedPage && _jsx(UserAgreement, {}), _jsx(Header, {}), _jsx("div", { className: "flex-1", children: determineContentToRender() }), globalNotification.showNotification && _jsx(GlobalNotification, {}), _jsx(Footer, {})] }));
};
export default App;
