import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CatHeading } from "blocks-nextjs/blocks-react/components/Heading/Heading";
import { CatIconSuccess } from "blocks-nextjs/blocks-react/components/Icons/Success/Success";
import { CatIconWarningCircle } from "blocks-nextjs/blocks-react/components/Icons/WarningCircle/WarningCircle";
import { CatToast } from "blocks-nextjs/blocks-react/components/Toast/Toast";
import { CatToastGroup } from "blocks-nextjs/blocks-react/components/ToastGroup/ToastGroup";
import React from "react";
import useGlobalStore from "../../store/useGlobalStore";
export var Variant;
(function (Variant) {
    Variant["error"] = "error";
    Variant["success"] = "success";
    Variant["warning"] = "warning";
})(Variant || (Variant = {}));
const GlobalNotification = () => {
    const { globalNotification, setGlobalNotification } = useGlobalStore((state) => ({
        globalNotification: state.globalNotification,
        setGlobalNotification: state.setGlobalNotification
    }));
    const onToastClose = () => {
        setGlobalNotification({ showNotification: false });
    };
    return (_jsx(CatToastGroup, { isInline: true, verticalPosition: "top", children: _jsx(React.Fragment, { children: _jsxs(CatToast, { onBlToastClose: onToastClose, variant: globalNotification.type, children: [globalNotification.type === Variant.success && _jsx(CatIconSuccess, { slot: "before" }), globalNotification.type === Variant.error && _jsx(CatIconWarningCircle, { slot: "before" }), _jsx(CatHeading, { variant: "label-sm", children: globalNotification.message })] }) }, ".0") }));
};
export default GlobalNotification;
