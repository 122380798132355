import { jsx as _jsx } from "react/jsx-runtime";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import axios from "axios";
import "blocks-nextjs/blocks-web-components/design-tokens/bedrock/tokens.css";
import React from "react";
import ReactDOM from "react-dom/client";
import { AuthProvider } from "react-oauth2-code-pkce";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./index.css";
import { getAuthEndpoint, getClientId, getLogoutEndpoint, getLogoutRedirect, getTokenEndpoint, isMockSever } from "./lib/getEnvVariables";
import { apiConfigs, initializeMockServer } from "./lib/mock-server/mockServer";
import { mockRoutes } from "./lib/mock-server/src/lib/routes";
const root = document.getElementById("root");
//const root = document.getElementById("root")!;
const authConfig = {
    authorizationEndpoint: getAuthEndpoint(),
    clientId: getClientId(),
    extraAuthParameters: {
        application: "/OneCat",
        layout: "oneCol"
    },
    logoutEndpoint: getLogoutEndpoint(),
    logoutRedirect: getLogoutRedirect(),
    onRefreshTokenExpire: (event) => event.logIn(),
    postLogin: () => {
        const initUrl = localStorage.getItem("init-url");
        window.location.assign(initUrl);
        localStorage.removeItem("init-url");
    },
    preLogin: () => {
        localStorage.setItem("init-url", window.location.href);
    },
    redirectUri: `${window.location.origin}/signin-oidc`,
    scope: `openid profile offline_access ${getClientId()}`,
    storageKeyPrefix: "access_",
    tokenEndpoint: getTokenEndpoint()
};
// Mock server initialization, for now only for dev server
if (isMockSever()) {
    const routes = [...mockRoutes, ...apiConfigs];
    initializeMockServer(routes);
}
// Ensures cookie is sent
axios.defaults.withCredentials = true;
const queryClient = new QueryClient();
ReactDOM.createRoot(root).render(_jsx(React.StrictMode, { children: _jsx(AuthProvider, { authConfig: authConfig, children: _jsx(BrowserRouter, { children: _jsx(QueryClientProvider, { client: queryClient, children: _jsx(App, {}) }) }) }) }));
