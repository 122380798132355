import { Response } from "miragejs";
import { HttpStatusCode } from "../../../../../utils/src/lib/constants/StatusCodes";
export function assetsListResponse() {
    return new Response(HttpStatusCode.OK, {}, {
        AssetDetails: [
            {
                assetId: "bbfe281c-dbd2-4939-b048-bf421d96663e",
                assetMetadata: {
                    make: "CAT",
                    model: "330",
                    modelYear: 2022,
                    productFamily: {
                        code: "MHEX",
                        iconUrl: "https://s7d2.scene7.com/is/image/Caterpillar/CM20170418-51089-64127",
                        name: "MEDIUM HYDRAULIC EXCAVATOR"
                    },
                    serialNumber: "XNB20278"
                },
                assetName: "433-330",
                assetSerialNumber: "XNB20241",
                assetSourceId: "381",
                assetSourceType: "Machine",
                location: {
                    lastReportedTime: "2024-06-07T10:25:30.000000000",
                    position: {
                        coordinates: ["41.8866636", "-87.717822"].map(Number),
                        type: "points"
                    },
                    streetAddress: "300 N Central Park Ave, Chicago"
                },
                soc: "84.0",
                timestamp: "2024-06-07T10:21"
            },
            {
                assetId: "bbfe281c-dbd2-4939-b048-bf421d966632",
                assetMetadata: {
                    make: "CAT",
                    model: "330",
                    modelYear: 2022,
                    productFamily: {
                        code: "MHEX",
                        iconUrl: "https://s7d2.scene7.com/is/image/Caterpillar/CM20170418-51089-64127",
                        name: "MEDIUM HYDRAULIC EXCAVATOR"
                    },
                    serialNumber: "XNB20278"
                },
                assetName: "233-330",
                assetSerialNumber: "XNB20241",
                assetSourceId: "341",
                assetSourceType: "Machine",
                location: {
                    lastReportedTime: "2024-06-07T10:25:30.000000000",
                    position: {
                        coordinates: ["41.8866636", "-87.717822"].map(Number),
                        type: "points"
                    },
                    streetAddress: "300 N Central Park Ave, Chicago"
                },
                soc: "84.0",
                timestamp: "2024-06-07T10:21"
            },
            {
                assetId: "bbfe281c-dbd2-4939-b048-bf421d966631",
                assetMetadata: {
                    make: "CAT",
                    model: "330",
                    modelYear: 2022,
                    productFamily: {
                        code: "MHEX",
                        iconUrl: "https://s7d2.scene7.com/is/image/Caterpillar/CM20170418-51089-64127",
                        name: "MEDIUM HYDRAULIC EXCAVATOR"
                    },
                    serialNumber: "XNB20278"
                },
                assetName: "133-330",
                assetSerialNumber: "XNB20241",
                assetSourceId: "321",
                assetSourceType: "Machine",
                location: {
                    lastReportedTime: "2024-06-07T10:25:30.000000000",
                    position: {
                        coordinates: ["41.8866636", "-87.717822"].map(Number),
                        type: "points"
                    },
                    streetAddress: "300 N Central Park Ave, Chicago"
                },
                soc: "84.0",
                timestamp: "2024-06-07T10:21"
            },
            {
                assetId: "13bc13f6-6f05-4096-b058-81bd4550386c",
                assetMetadata: {
                    make: "PSW",
                    model: "",
                    modelYear: "",
                    productFamily: {
                        code: "PSW",
                        iconUrl: "",
                        name: "Pon Energy Rental"
                    },
                    serialNumber: "262343"
                },
                assetName: "BQ6-380",
                assetSerialNumber: "26234389",
                assetSourceId: "391",
                assetSourceType: "ESS",
                location: {
                    lastReportedTime: "2024-05-27T12:31:30.000000000",
                    position: {
                        coordinates: ["41.8766636", "-87.72345"].map(Number),
                        type: "points"
                    },
                    streetAddress: "300 N Central Park Ave, Chicago"
                },
                soc: "100.0",
                timestamp: "2024-06-13T14:14"
            },
            {
                assetId: "185dc18e-f465-45f9-8018-c75677ea1add",
                assetMetadata: {
                    make: "BAB",
                    model: "",
                    modelYear: "",
                    productFamily: {
                        code: "BAB",
                        iconUrl: "",
                        name: "Big Ass Battery"
                    },
                    serialNumber: ""
                },
                assetName: "RL3-Rental",
                assetSourceId: "301",
                assetSourceType: "ESS",
                location: {
                    lastReportedTime: "2024-06-13 15:40:00",
                    position: {
                        coordinates: ["41.8666636", "-87.707822"].map(Number),
                        type: "points"
                    },
                    streetAddress: "300 N Central Park Ave, Chicago"
                },
                soc: "67.36842",
                timestamp: "2024-06-13T15:40"
            }
        ],
        customerName: "Customer 1",
        owner: "User1",
        projectId: "ZYY12345",
        projectName: "Site 1",
        streetAddress: "300 N Central Park Ave, Chicago"
    });
}
